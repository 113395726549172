<template>
    <div class="row">
            <span class="col-sx-12 col-sm-12 col-md-12 d-sm-block d-md-block d-sx-block d-lg-none questionListBtn" v-if="selectedquestion" @click="openNav()">&#9776; Questions</span>
        <div id="mySidenav" class="questionList col-lg-4 col-xl-3 col-md-12 col-sx-12 col-sm-12" :class="{'sidenav':selectedquestion}">
            <div class="questionList col-sx-12 col-sm-12 col-md-12 d-sm-block d-sx-block d-md-block d-lg-none d-md-none pb-3 pt-3 mobileQuestTitle" >
                <a v-if="selectedquestion" class="closebtn" @click="closeNav()">&times;</a>
            <h4>Summarize Written Text
                </h4>
            </div>
            <app-IntervalList ref="intervalList" :questionLists="questionLists" :questionId="'written_text_id'"/>
        </div>
        <div class="questonhelp col-lg-8 col-xl-9 d-none d-lg-block" v-if="!selectedquestion">
             <h4 class="text-center mb-4 fancy"><span>Summarize Written Text</span></h4>
            <h5 class="mb-1">Introduction</h5>
            <p class="para mb-2">You will get a passage and you've to summarize it using one sentence. Type your response in the box at the bottom of the screen. You'll have 10 minutes to finish this task.</p>
            <h5 class="mb-1">Skills assessed</h5>
            <p class="heading mb-2">Reading and writing</p>
            <h5 class="mb-1">Number of questions</h5>
            <p class="para mb-2"> 1 - 2 </p>
            <template v-if="user_type == 'premium'">
            <h5 class="mb-1">KEY POINTS</h5>
            <ul class="ml-5">
                <li>You will have to write the summary in only one sentence. This means, only one full stop is allowed. You can use commas and semicolons for more than once.</li>
                <li>You must include the main points from the given paragraphs. You’ll get the ‘word count’ at the bottom of the text box.</li>
                <li>You will be judged for reading, writing, grammar, vocabulary, content and style.</li>
                <li>You also have a choice to cut, copy, or paste the text.</li>
            </ul> 
            <p><strong>Cut:</strong> Select text from your answer that you wish to remove and left-click “Cut”.</p>
            <p><strong>Copy:</strong> Select text from your answer that you wish to copy and left-click “Copy”.</p>
            <p><strong>Paste:</strong> Place the cursor where you wish to paste the cut/copied text and left-click “Paste”.</p>
            </template>
        </div>
        <div class="questiondesc col-lg-8 col-xl-9 col-md-12 col-sm-12 col-sx-12" :class="{'col-12':(selectedquestion.question_id != 0)}" v-if="selectedquestion">
            <b-overlay class="fixedHeight" :show="show" rounded="sm" >
            <h4>Summarize Written Text</h4>
            <p class="questHeading">Read the passage below and summarize it using one sentence. Type your response in the box at the bottom of the screen. You have 10 minutes to finish this task. Your response will be judged on the quality of your writing and on how well your response presents the key points in the passage.</p>
            <div class="row mb-2">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 titleDiv">
                <div class="prepare" ><p>Remain </p><app-Timer ref="timer"/></div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 text-right pr-0" >
            <app-QuestionStatus ref="QuestionResetStatus" :selectedQuestion="selectedquestion" :question_id="selectedquestion.written_text_id" :idName="'written_text_id'" :tableName="'tblSummerizeWrittenText'"/>
            </div>
            </div>
            
            <p class="questdesc mb-4">{{selectedquestion.written_text}}</p>
            <textarea id='text' data-gramm="false" v-model="inputText" @keydown="recheckInput" spellcheck="false"></textarea>
            <p style="padding-top: 5px !important;padding-left: 5px !important;"><strong>Word Count: {{wordCount}}</strong></p>
         
            <!-- <div class="row mt-3">
                <div class="col-12" v-if="suggestions != null">
                        <ul v-for="item in suggestions" :key="item.answer_id">
                            <li>Sentence: <strong>{{item.sentence}}</strong></li>
                            <li  class="suggestion">Mistake Description: {{item.message}}</li>
                            <li class="suggestion">Replacement Suggestions:
                                <template v-for="(result,index) in item.replacements">
                                    <span :key="index"> {{result.value}} , </span>
                                </template>
                                </li>
                        </ul>
                        <div v-if="suggestions.length ==0">
                            <h5>No Mistake Found</h5>
                        </div>
                </div>
            </div> -->

            <!-- <div class="divTable blueTable" v-if="suggestions">
                <div class="divTableBody">
                    <div class="divTableRow">
                        <div class="divTableCell ">Content</div>
                        <div class="divTableCell">{{this.score.content}}</div>
                    </div>
                    <div class="divTableRow">
                        <div class="divTableCell">Form</div>
                        <div class="divTableCell">{{this.score.form}}</div>
                    </div>
                    <div class="divTableRow">
                        <div class="divTableCell">Grammar and Spellings</div>
                        <div class="divTableCell">{{this.score.spellingAndGrammer}}</div>
                    </div>
                    <div class="divTableRow">
                        <div class="divTableCell">Vocabulary</div>
                        <div class="divTableCell">{{this.score.vocab}}</div>
                    </div>
                </div>
            </div> -->
            </b-overlay>
             <div class="row">
                <div class="col-lg-12 col-xl-6 col-md-12 text-left col-sm-12 questBtnDiv mt-5 pl-0">
                    <b-button :disabled="inputText == ''" class="mr-2 pl-4 pr-4" variant="outline-info" @click="submitanswer()">Submit</b-button>
                    <b-button variant="outline-warning" class="mr-2 pl-4 pr-4" @click="redoQuest()">Re-do</b-button>
                    <!-- <b-button variant="outline-dark" class="mr-2 pl-4 pr-4" @click="showModal()">Dict Mode</b-button> -->
                    <b-button :disabled="apiResults ==null" variant="outline-success" class="mr-2 pl-4 pr-4" v-b-modal.result-Modal>
                        <b-spinner v-if="resultStatus=='In-Process'" small type="grow"></b-spinner>
                        {{resultStatus}}
                    </b-button>
                    <toggle-button :width="75" :height="28" v-model="checkAnswerFlag" :value="false" :color="{checked: '#104e94', unchecked: '#e9ecef', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'en-US', unchecked: 'en-GB'}"/>
                </div>
                <div class="col-6 text-right mt-3 pl-0 d-none d-xl-block mt-5">
                    <div class="row">
                        <div class="col-2 p-0">
                             <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" variant="outline-info" class="mr-2 prevQuestion pl-3 pr-3">Prev</b-button>
                        </div>
                        <div class="col-8 p-0">
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-icon shift-v="1.2" scale="1.3" icon="search"></b-icon>
                                </b-input-group-prepend>
                                <b-form-input v-model="searchKey" type="search" placeholder="Question title"></b-form-input>
                                <b-input-group-append>
                                <b-button @click="searchQuestion()" variant="outline-secondary">Search</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <div class="col-2 p-0 text-left">
                             <!-- <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-2 prevQuestion pl-3 pr-3">Prev Quest</b-button> -->
                        <b-button @click="$refs.intervalList.changeQuestion('next')" variant="outline-info" class="ml-2 nextQuestion pl-3 pr-3">Next</b-button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-12 displayInline" align="center" v-if="selectedquestion">
                <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-5 prevQuestion">Prev Question</b-button>
                    <b-button variant="success" @click="submitanswer()">Check Score And Suggetions</b-button>
                    <b-button @click="$refs.intervalList.changeQuestion('next')" class="ml-5 nextQuestion">Next Question</b-button>
            </div> -->
        </div>
         <b-modal  ref="search-Result" size="lg" scrollable centered hide-footer title="Search Result" ok-only ok-title="Cancel" ok-variant="secondary">
            <b-container v-if="searchKey!='' | searchQuestionList!=null"><app-IntervalList name="search" :popup="true" :parentFunctionCalling="getQuestionDetail" :questionLists="searchQuestionList" :questionId="'written_text_id'"/></b-container>
        </b-modal>
        <b-modal id="result-Modal" size="lg" centered hide-footer title="AI Result">
         <b-container class="resultModel" >
               <app-WritingResultPresent :apiResults="apiResults"/>
         </b-container>
         <b-button class="mt-3" variant="outline-dark" left @click="$bvModal.hide('result-Modal')">Close Me</b-button>
       </b-modal>
       <div class="col-12 mt-5 d-none d-lg-block" v-if="selectedquestion!=null">
            <app-DiscussionTab ref="discussionTab" :question_id="currentQuestion" :tableName="'tblUserWrittenSummery'"/>
        </div>
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import Timer from '../reuseable/timer'
import WritingResultPresent from '../reuseable/writeResultPresent'
//import LocalStorageService from '../../store/LocalStorageService'
import unirest from "unirest"
import IntervalList from '../reuseable/intervalList'
import QuestionStatus from '../reuseable/questStatus'
import DiscussionTab from '../reuseable/discussionTab'
export default {
    components:{
        'app-Timer':Timer,
        'app-IntervalList': IntervalList,
        'app-WritingResultPresent':WritingResultPresent,
        'app-QuestionStatus':QuestionStatus,
        'app-DiscussionTab':DiscussionTab
    },
    data(){
        return{
            questionLists:{
                type: Object,
                required: true
            },
            show:false,
            buttonText:'Submit Answer',
            selectedquestion: null,
            inputText:'',
            wordCount:0,
            limitCharter: 75,
            limitDone:false,
            charCount:0,
            user_type:'Free',
            selectedTime: 0,
            timeLeft: '00:00',
            startFlag:false,
            userAnswerResponse:null,
            suggestions:null,
            copyToClipBoard:'',
            checkAnswerFlag:false,
            userAnswer:{
                written_text_id:0,
                user_id:0,
                user_answer:'',
                url:'',
                api_result:null,
                mock_test_id:0,
                user_result:''
            },
            score:{
                content: 0,
                form: 0,
                spelling:2,
                grammer:2,
                vocab: 0,
                structure:0,
            },
            //user_type:'Free',
            currentQuestion:0,
            apiResults:null,
            resultStatus:'AI-Result',
            isShowModal: false,
            modelTitle:'heading',
            aud:null,
            searchKey:'',
            searchQuestionList:null,
            currentUser:null,
            AIWritingData:{
                analysisType:"",
                langType:"",
                questType:"",
                userAnswer:"",
                question:"",
                url:"AIWritting/AIMarkingResult",
                question_Id:0
            }
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'writtingmodule/fetchQuestion',
            saveAnswer: 'writtingmodule/saveAnswer',
            fetchUserUsage:'auth/saveDashBoardData',
        }),
        ...mapGetters({
            getQuestionList: 'writtingmodule/questions',
            answerResponse: 'writtingmodule/AnswerResponse',
            GetUserUsageResponse:'auth/getDashBoardResponse'
        }),
        redoQuest(){
            this.getQuestionDetail(this.selectedquestion.written_text_id)
        },
        openNav(){
            document.getElementById("mySidenav").style.width = "100%";
        },
        closeNav(){
            document.getElementById("mySidenav").style.width = "0";
        },
        searchQuestion(){
            const key=this.searchKey.trim().toLowerCase()
            const searchedQuestion= this.questionLists.filter((item)=>{
                return item.title.toLowerCase().includes(key)
            });
            this.searchKey = '';
            this.$refs.timer.pauseTime()
            ////console.log(searchedQuestion)
            if(searchedQuestion.length>0){
                if(searchedQuestion.length>1){
                   //console.log(searchedQuestion)
                   this.searchQuestionList=searchedQuestion
                   this.$refs['search-Result'].show()
                }
                else{
                    //console.log(searchedQuestion)
                    return this.getQuestionDetail(searchedQuestion[0].written_text_id)
                }
            }
            else{
                return this.$alert("No record found with the given title", "Search Result");
            }
        },
        updateSelectQuestionStatus(question,id){
            ////console.log(this.selectedquestion)
            this.selectedquestion = question
            const currectQuestionIndex=this.questionLists.findIndex(element=>
            element.question_id==id)
            this.questionLists[currectQuestionIndex]=this.selectedquestion
            ////console.log(question)
        },
        getQuestionDetail(questionId){
             if(questionId==0) return this.selectedquestion=null
            if(this.$refs['search-Result'].isVisible) this.$refs['search-Result'].hide()
            this.currentQuestion=questionId
            this.resetAll();
            this.userAnswer.written_text_id = questionId;
            this.selectedquestion = this.questionLists.filter(function (item) {
                    return item.written_text_id == questionId;
                })[0];
            if(this.$refs.QuestionResetStatus)
            this.$refs.QuestionResetStatus.resetQuestionDetails(this.selectedquestion,questionId)
            //////console.log(this.selectedquestion.written_text)
            this.startFlag = false
            this.$nextTick(function(){
                this.$refs.discussionTab.updateQuestionId(questionId)
                this.$refs.timer.setTime(600)
            })
        },
        recheckInput:function(evt){
            if(evt.keyCode == 32){
            if(this.limitCharter == this.wordCount)
            {
                this.limitDone = true;
                    this.charCount = this.inputText.length	
            }
            }
                if(this.limitDone & evt.keyCode !=32){
                    this.limitDone = false;
            }
        },
        timerEnd(){
            //alert('Time Finish')
        },
        userUsage(){
        var data={
            user_id: this.currentUser.user_id,
            url:'users/userUsageCount'
          }
          this.fetchUserUsage(data).then(()=>{
            ////console.log(this.GetUserUsageResponse())
           this.currentUser.writing_Counter= this.GetUserUsageResponse().data.writingCount
            //this.$ls.remove('PackageType')
          })
        },
        checkUserAccess(){
            const user =this.currentUser
            if(user.user_type == 'Free' & user.writing_Counter>=5)
            {
                return false
            }
            else if(user.user_type == 'standard' & user.writing_Counter>=10)
            {
                return false
            }
            else{
                this.currentUser.writing_Counter++
                return true
            }
        },
        submitanswer(){
            try{
                    if(!this.checkUserAccess())
                        {
                            return this.$alert(
                                "Please upgrade your plan for further practice your today limit is over",
                                "Warning",
                                "warning",
                                {
                                    confirmButtonText: "Cannot Proceed"
                                })
                        }
                        if(this.inputText.length<10){
                    return this.$alert('No answer found, Against this question.')
                    }
                        this.resultStatus='In-Process'
                        this.show=true
                        var userdata =this.$ls.get('user')
                        this.userAnswer.user_id = userdata.user_id
                        let lang="en_UK";
                        lang=(this.checkAnswerFlag)? "en-US":"en-GB";
                        lang=(lang=="en-GB")?"en_UK":"en_US";
                        this.AIWritingData.langType=lang;
                        this.AIWritingData.analysisType="Academic"
                        this.AIWritingData.questType="swt"
                        this.AIWritingData.userAnswer=this.inputText
                        this.AIWritingData.question=this.selectedquestion.written_text
                        this.AIWritingData.question_Id=this.currentQuestion
                        var vm = this
                        if(this.inputText.length<10){
                        return this.$alert('No answer found, Against this question.')
                    }
                    console.log(this.AIWritingData)
                        this.saveAnswer(this.AIWritingData).then(()=>{
                        let AIResponse= this.answerResponse();

                        vm.userAnswer.user_answer = vm.inputText;
                        vm.apiResults=AIResponse.apiResults;
                        vm.userAnswer.api_result=JSON.stringify(vm.apiResults)
                        vm.userAnswer.mock_test_id=0
                        vm.userAnswer.url = 'userwrittensummery/createusersummery'
                        //console.log(vm.userAnswer.user_answer)
                        var overAllScore =(vm.apiResults.content+vm.apiResults.form
                            +vm.apiResults.grammer+vm.apiResults.vocab)
                        vm.userAnswer.user_result='Your Score: '+overAllScore+'/7'
                        vm.resultStatus='AI-Result Ready'
                        vm.show=false
                        vm.saveAnswer(vm.userAnswer).then(()=>{
                        ////console.log(this.answerResponse())
                            vm.userAnswerResponse = vm.answerResponse().data
                        });


                        });
             }
            catch(error){
                this.show=false
            }
        },
        submitanswerOld() {
            try
            {
        if(!this.checkUserAccess())
        {
            return this.$alert(
                "Please upgrade your plan for further practice your today limit is over",
                "Warning",
                "warning",
                {
                    confirmButtonText: "Cannot Proceed"
                })
        }
        this.resultStatus='In-Process'
        this.show=true
        var userdata =this.$ls.get('user')
        this.userAnswer.user_id = userdata.user_id
        const lang=(this.checkAnswerFlag)? "en-US":"en-GB";
        const  req = unirest("POST", "https://grammarbot.p.rapidapi.com/check");
            req.headers({
                "content-type": "application/x-www-form-urlencoded",
                "x-rapidapi-key": process.env.VUE_APP_GRAMMER_API_KEY,
                "x-rapidapi-host": "grammarbot.p.rapidapi.com",
                "useQueryString": true
            });

            req.form({
                "text": this.inputText,
                "language": lang//"en-US"
            });
            var vm = this
            req.end(function (res) {
                if (res.error) throw new Error(res.error);
                //console.log(res.body)
                vm.suggestions = res.body.matches;
                vm.checkScore()
                vm.userAnswer.user_answer = vm.inputText;
                vm.suggestions=(vm.suggestions.length<=0) ?'No spelling and grammar error':vm.suggestions
                vm.userAnswer.api_result = JSON.stringify(vm.suggestions)
                vm.userAnswer.mock_test_id=0
                vm.userAnswer.url = 'userwrittensummery/createusersummery'
                //console.log(vm.userAnswer.user_answer)
                var overAllScore =(vm.apiResults.content+vm.apiResults.form
                    +vm.apiResults.grammer+vm.apiResults.vocab)
                vm.userAnswer.user_result='Your Score: '+overAllScore+'/7'
                vm.resultStatus='AI-Result Ready'
                vm.show=false
                vm.saveAnswer(vm.userAnswer).then(()=>{
                ////console.log(this.answerResponse())
                    vm.userAnswerResponse = vm.answerResponse().data
                });
            })
         }
            catch(error){
                this.show=false
            }
        },
        resetAll() {
            this.inputText = ''
            this.userAnswer.url = ''
            this.userAnswer.user_answer = ''
            this.suggestions = null
            this.userAnswer={
                user_id:this.$ls.get('user').user_id,
                written_text_id:0,
                user_answer:'',
                url:'',
                api_result:null,
                user_result:'',
                mock_test_id:0
            }
            this.score={
                content: 0,
                form: 0,
                spelling:2,
                grammer:2,
                vocab: 0,
                structure:0,
            }
            this.apiResults=null
            this.resultStatus='AI-Result'
            this.isShowModal= false
            this.aud=null
            this.searchKey=''
            this.searchQuestionList=null
            this.AIWritingData={
                analysisType:"",
                langType:"",
                questType:"",
                userAnswer:"",
                question:"",
                url:"AIWritting/AIMarkingResult"
            }
        },
        getSelectedText(){
        var txt = '';
        if (window.getSelection)
        {
            txt = window.getSelection();
        }
        else if (document.getSelection)
        {
            txt = document.getSelection();
        }
        else if (document.selection)
        {
            txt = document.selection.createRange().text;
        }
        else return;
        this.copyToClipBoard = txt;
        ////console.log(this.copyToClipBoard)
        },
        getSelectedRange(){
        var location = '';
        if (window.getSelection)
        {
        location = window.getSelection();
            var data = location.getRangeAt(0)
                //console.log(data)
            }
        },
        checkScore(){
            this.score={
                content: 0,
                form: 0,
                spelling:2,
                grammer:2,
                vocab: 0,
                structure:0,
                }
            if(this.inputText.length<=0){
                return this.score={
                content: 0,
                form: 0,
                spelling:0,
                grammer:0,
                vocab: 0,
                structure:0,
                }
            }
            var questionText=this.selectedquestion.written_text.toLowerCase().split(' ')
            var minSummaryWordsAsPara= Math.round(questionText.length/3)
            minSummaryWordsAsPara=(minSummaryWordsAsPara>60)? 60 :minSummaryWordsAsPara;
            var firstLetter = this.inputText.charAt(0);
            var firstWord = this.inputText.substr(0, this.inputText.indexOf(" "))
            var findFullStop = this.inputText.split('.')
            var checkFullStopInLast = this.inputText.trim().slice(-1);
            var beforeFirstFullStopContent=findFullStop[0]

            var pronounList=['i','we','you','he','she','it','they','me','us','her','him','it','them','mine'
            ,'ours','yours','her','his','theirs','my','our','your','myself','yourself','herself','itself',
            'ourself','yourself','themself'];

            var conjunctionWords=['therefore','however','moreover','thus','meanwhile','thereafter','indeed',
            'instead','consequently','eventually','finally','still','besides','because',
            'since','so that','in order','though','although','even though','while','but',
            'yet','or','so','nor','after','as long as','as soon as','before','by the time',
            'now that','once','since','till','until','when','whenever','while'];

            var checkFullStop = false
            var checkWordCount = false
            var checkFirstLetterCapital = false
            var checkFirstPronoun = false
            var checkMatchWords = false
            var checkConjunctionWords = false

            // Content Scoring
            if(firstLetter == firstLetter.toUpperCase())
                checkFirstLetterCapital = true;
            if(pronounList.indexOf(firstWord.toLowerCase())>-1) 
                checkFirstPronoun = true

            if(findFullStop.length == 2 && checkFullStopInLast == '.')
                checkFullStop = true;
            
            var inputArray=beforeFirstFullStopContent.split(' ');
            var matchWordsCount=0
            //var unMatchedCount=0
            var conjCount=0
for(var i=0;i<questionText.length;i++){
                questionText[i]=questionText[i].trim();
            }
            for (var i=0; i< inputArray.length;i++){
                const element=inputArray[i].toLowerCase().replace(',','').replace('.','')
                if(questionText.indexOf(element)>-1) matchWordsCount++
               // else unMatchedCount++
                if(conjunctionWords.indexOf(element)>-1) conjCount++
            }

            if(matchWordsCount>3) checkMatchWords = true
            if(conjCount>0) checkConjunctionWords = true
            if(checkFirstLetterCapital) this.score.structure = 0.25
            if(checkFullStop) this.score.structure += 0.75
            
            if(checkConjunctionWords) this.score.structure += 1

            if(!checkFirstPronoun) this.score.content = 0.5
            if(checkMatchWords) this.score.content += 0.5

            // Form scoring. also effect content
            var contentWordCount=inputArray.length
            if(contentWordCount > 5 && contentWordCount <= 75) checkWordCount = true;

            if(contentWordCount >= minSummaryWordsAsPara) this.score.content +=1
            
            if(Math.round(this.score.content) >=1.5)this.score.vocab = 2
            else if(Math.round(this.score.content) >=1) this.score.vocab = 1.5
            else if(Math.round(this.score.content) >=.5) this.score.vocab = 1
            else if(Math.round(this.score.content) >= .25) this.score.vocab = .5
            else this.score.vocab =0

            if(checkWordCount & checkFullStop)this.score.form = 1
            else if(checkWordCount) this.score.form = 0.5


            // Spelling and grammar check.
            var transcript= this.inputText
             var grammerMistakesCount=0
            var spellingMistakeCount=0
            if(this.suggestions!="No spelling and grammmar error")
            {
            for (let index = this.suggestions.length-1; index >=0; index--) {
                const element = this.suggestions[index]
                if(this.suggestions[index].rule.issueType !='misspelling') grammerMistakesCount++
                else spellingMistakeCount++
                const offset= element.offset
                const length= element.length
                const word= transcript.substring(offset,offset+length)
                transcript = transcript.substring(0, offset) + '<span id="'+index+'" class="text-danger" style="background:yellow;">'+word+'</span>' + transcript.substring(offset+length);
            }
            }

            this.score.grammer=this.score.grammer-(grammerMistakesCount * 0.25);
            if(this.score.grammer<0)this.score.grammer=0

            this.score.spelling=this.score.spelling-(spellingMistakeCount * 0.25);
            if(this.score.spelling<0)this.score.spelling=0

            this.apiResults={
                    content: this.score.content,
                    form: this.score.form,
                    spelling:this.score.spelling,
                    grammer:this.score.grammer,
                    vocab: this.score.vocab,
                    structure: this.score.structure,
                    transcript:transcript,
                    text:this.inputText,
                    quest:'swt',
                    maxMarks:7,
                    contentMax:2 
                }
            //console.log(transcript)
            //console.log(this.score)
        }
    },
    beforeMount(){
        this.questionservice('configwrittensummery/getallsummeries').then(()=>{
            this.questionLists = this.getQuestionList().data
           // var userdata = LocalStorageService.getUser()
           var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.user_type = userdata.user_type
            this.currentUser=userdata
            this.userUsage()
            ////console.log(this.questionLists)
        })
    },
    watch: {
        inputText: function (val) {
        if(this.limitDone & this.charCount <= val.length) {
        this.inputText = this.inputText.substr(0, this.charCount)
        }
        if(this.charCount > val.length){
        this.limitDone = false;
        }
        var regex = /\s+/gi;
            this.wordCount =(val.length>0)? val.trim().replace(regex, ' ').split(' ').length:0;
        
        },
    },
    
}
</script>
<style>
#answerTrans span{
    background: yellow!important;
    color:red!important;
}
#answerTrans span.possibleMistake{
    background: #f33176!important;
    color: #fff!important;

}
.noteDiv{
    text-align: right;
    width: 100%;
    font-weight: 700;
    position: absolute;
    top: -30px;
    right: 10px;
}
</style>
<style scoped>
textarea {
    height: 30vh;
    width: 100%;
    border: 1px solid #ccc;
    resize: none;
}
.suggestion{
    color: red;
}

div.blueTable {
  background-color: #EEEEEE;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}

.divTableCell, .divTableHead {
  border: 1px solid #AAAAAA;
  padding: 3px 2px;
}
.divTableBody .divTableCell {
  font-size: 14px;
}



/* DivTable.com */
.divTable{ display: table; }
.divTableRow { display: table-row; }
.divTableHeading { display: table-header-group;}
.divTableCell, .divTableHead { display: table-cell;}
.divTableBody { display: table-row-group;}
</style>